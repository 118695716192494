<template>
    <div class="page">
        <publicHeader :ifReverse="true"/>
        <aboutTemp/>
    </div>
</template>

<script>
import publicHeader from '@/components/public/Header.vue'
import aboutTemp from '@/components/page/about/AboutContent.vue'
export default {
    name: 'Home',
    components: {
        publicHeader,
        aboutTemp
    }
}
</script>

<style scoped lang="scss">
    .page{
        position: relative;
    }
</style>
