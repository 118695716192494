<template>
    <section id="aboutContent">
		<div class="Headbannerfield" id="headbanner">
			<div class="starback">
				<div class="star"></div>
			</div>
			<div class="shinebox">
				<div id="lightroad" v-bind:class="[{hide : HeadAniHide}]" ref="lightroad"></div>				
			</div>
			<div class="textcontent w1200">
				<h3 class="title">LIGHT UP YOUR WAY<br>IN DIGITAL WORLD</h3>
				<p class="text lh-l">數位行銷、創意設計、軟體開發技術，結合3大領域的專業團隊，不管市場脈動如何瞬息萬變，HOWGROUP怎漾集團一路精確導航，助您目標完美實現!</p>
			</div>
		</div>
		<div class="Numberfield" ref="Numberfield">
			<div class="numbercontent w1200">
				<div class="item">
					<div class="numbox">
						<h1 class="num">{{Numberfield.clientsNum}}<sup>+</sup></h1>						
					</div>
					<h5 class="text">橫跨歐美亞洲客戶</h5>
				</div>
				<div class="item">
					<div class="numbox">
						<h1 class="num">{{Numberfield.daysNum}}</h1>
						<h5 class="text">天</h5>
					</div>
					<h5 class="text">專業執行</h5>
				</div>
				<div class="item">
					<h4 class="text">
						<span>
							洞悉市場+專業技術
						</span>
						<span>
							= 無限可能！
						</span>
					</h4>
				</div>
			</div>
		</div>
		<div class="Howamazingfiled" ref="Howamazingfiled">
			<div class="howamzingcontent w1200">
				<div class="imgfield">
					<video src="@/assets/img/about/ballvideo.mp4" alt="" autoplay loop muted playsinline webkit-playsinline></video>
				</div>
				<div class="textfield">
					<div class="textbox">
						<h3 class="title">HOW AMAZING</h3>
						<h6 class="subtitle">行銷問題的最好解答</h6>
						<p class="text lh-l">
							無論品牌遇到什麼問題，HOWGROUP怎漾集團都能完美掌握，提供最合適的全面創意行銷方案。
						</p>
					</div>
					<router-link class="arrowbtn" to="/works">
						<img src="@/assets/img/index/arrow.svg" alt="">
						<h6 class="text txt-bold">View projects</h6>
					</router-link>
				</div>
			</div>
		</div>
		<div class="companyfield">
			<div class="companycontent">
				<div class="titlefield w1200">
					<div class="textbox">
						<div class="top">
							<p class="text txt-bold">{{companyfield.slidedata[companyfield.comSwiperIndex].cityname}}</p>
							<div class="dash"></div>
						</div>
						<div class="center">
							<img v-bind:src="companyfield.slidedata[companyfield.comSwiperIndex].logo" alt="">
						</div>
						<div class="bottom">
							<h5 class="text txt-bold">
								{{companyfield.slidedata[companyfield.comSwiperIndex].text_01}}
							</h5>
							<h5 class="text txt-bold">
								{{companyfield.slidedata[companyfield.comSwiperIndex].text_02}}
							</h5>
							<div class="arrowbtn">
								<div class="img swiper-button-prev" slot="button-prev"></div>
							</div>
						</div>
					</div>
					<div class="titlebox">
						<h3 class="title txt-bold">COMPANY</h3>
					</div>
				</div>
				<swiper class="swiperbox" :options="companyfield.swiperOption" ref="comSwiper">
					<swiperSlide v-for="(item,index) in companyfield.slidedata" :key="index">
						<div class="swiperitem" v-bind:style="{ backgroundImage: companyfield.slidedata[(index-companyfield.slideoffset)<0?2:index-companyfield.slideoffset].slideimg }"></div>
					</swiperSlide>			
				</swiper>
				
			</div>
		</div>
		<div class="our4pfield">
			<div class="our4pcontent w1200">
				<div class="left">
					<div class="titlebox">
						<h3 class="title txt-bold">
							{{our4pfield.our4pdata[our4pfield.activeIndex].title_01}}<br>
							{{our4pfield.our4pdata[our4pfield.activeIndex].title_02}}
						</h3>
						<h6 class="text txt-bold">{{our4pfield.our4pdata[our4pfield.activeIndex].subtitle}}</h6>
					</div>
					<div class="textbox">
						<p class="text" v-bind:class="our4pfield.our4pdata[our4pfield.activeIndex].color">{{our4pfield.our4pdata[our4pfield.activeIndex].text_01}}</p>
						<p class="text">{{our4pfield.our4pdata[our4pfield.activeIndex].text_02}}</p>
					</div>
					<div class="btnbox">
						<div class="btn" v-for="(item,index) in our4pfield.our4pdata" :key="index" v-bind:class="[{active : our4pfield.activeIndex == index}, our4pfield.our4pdata[our4pfield.activeIndex].color]" v-on:click="Our4pbtnClick(index)"></div>
					</div>
				</div>
				<div class="right">
					<img v-bind:class="our4pfield.our4pdata[our4pfield.activeIndex].aniclass" id="red_ball_01" src="@/assets/img/about/polygonbox/red_ball.png" alt="">
					<img v-bind:class="our4pfield.our4pdata[our4pfield.activeIndex].aniclass" id="red_ball_02" src="@/assets/img/about/polygonbox/red_ball.png" alt="">

					<img v-bind:class="our4pfield.our4pdata[our4pfield.activeIndex].aniclass" id="blue_ball_01" src="@/assets/img/about/polygonbox/blue_ball.png" alt="">
					<img v-bind:class="our4pfield.our4pdata[our4pfield.activeIndex].aniclass" id="blue_ball_02" src="@/assets/img/about/polygonbox/blue_ball.png" alt="">

					<img v-bind:class="our4pfield.our4pdata[our4pfield.activeIndex].aniclass" id="yellow_ball_01" src="@/assets/img/about/polygonbox/yellow_ball.png" alt="">
					<img v-bind:class="our4pfield.our4pdata[our4pfield.activeIndex].aniclass" id="yellow_ball_02" src="@/assets/img/about/polygonbox/yellow_ball.png" alt="">
					<img v-bind:class="our4pfield.our4pdata[our4pfield.activeIndex].aniclass" id="yellow_ball_03" src="@/assets/img/about/polygonbox/yellow_ball.png" alt="">
					<img v-bind:class="our4pfield.our4pdata[our4pfield.activeIndex].aniclass" id="yellow_ball_04" src="@/assets/img/about/polygonbox/yellow_ball.png" alt="">

					<img v-bind:class="our4pfield.our4pdata[our4pfield.activeIndex].aniclass" id="white_ball" src="@/assets/img/about/polygonbox/white_ball.png" alt="">
					<img v-bind:class="our4pfield.our4pdata[our4pfield.activeIndex].aniclass" id="yellow_halfball" src="@/assets/img/about/polygonbox/yellow_halfball.png" alt="">
					<img v-bind:class="our4pfield.our4pdata[our4pfield.activeIndex].aniclass" id="white_balltape" src="@/assets/img/about/polygonbox/white_balltape.png" alt="">
					<img v-bind:class="our4pfield.our4pdata[our4pfield.activeIndex].aniclass" id="blue_square" src="@/assets/img/about/polygonbox/blue_square.png" alt="">

					<img v-bind:class="our4pfield.our4pdata[our4pfield.activeIndex].aniclass" id="white_square" src="@/assets/img/about/polygonbox/white_square.png" alt="">
					<img v-bind:class="our4pfield.our4pdata[our4pfield.activeIndex].aniclass" id="red_square" src="@/assets/img/about/polygonbox/red_square.png" alt="">
					<img v-bind:class="our4pfield.our4pdata[our4pfield.activeIndex].aniclass" id="green_tube_01" src="@/assets/img/about/polygonbox/green_tube.png" alt="">

					<img v-bind:class="our4pfield.our4pdata[our4pfield.activeIndex].aniclass" id="green_tube_02" src="@/assets/img/about/polygonbox/green_tube.png" alt="">
					<img v-bind:class="our4pfield.our4pdata[our4pfield.activeIndex].aniclass" id="yellow_tape" src="@/assets/img/about/polygonbox/yellow_tape.png" alt="">
				</div>
			</div>
		</div>
		<div class="lettalkfield">
			<div class="lettalkcontent w1200">
				<div class="titlebox">
					<h3 class="txt-bold">LET’S TALK ABOUT</h3>
					<h3 class="txt-bold light">YOUR PROJECT</h3>
				</div>
				<div class="textbox">
					<p class="text lh-l">
						HOWGROUP怎漾集團敢承諾高效運用專業頂尖技能，掌握所有成功的一切可能性，助品牌完美達成目標！
					</p>
					<router-link class="arrowbtn" to="/contact">
						<img src="@/assets/img/index/arrow.svg" alt="">
						<h6 class="text txt-bold">Start here</h6>					
					</router-link>					
				</div>
			</div>
		</div>
        <!-- <img class="pc" src="~@/assets/img/web.jpg" alt="">
        <img class="ip" src="~@/assets/img/iphone.jpg" alt=""> -->
    </section>
</template>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import * as infinitelight from "@/assets/js/InfiniteLights.js";
import * as THREE from "three";

export default {
	name: 'aboutContent',
	data() {
		return {
			//public
			scrollDistence: window.scrollY,
			screenHeight: window.innerHeight,
			//specific
			numIsShow: false,
			numIsAdded:false,
			windowWidth:0,
			HeadAniHide:false,
			IsAniStart:false,
			//Section
			Headfield:{
			},
			Numberfield:{
				clientsNum:0,
				daysNum:0,
			},
			Howamazingfiled:{
				//ballimg:`${require('@/assets/img/about/ball.jpg')}`
			},
			companyfield:{
				comSwiperIndex:0,
				slideoffset:1,
				slidedata:[
					{
						slideimg: `url(${require('@/assets/img/about/com_01.jpg')})`,
						cityname:'TAIPEI',
						logo:`${require('@/assets/img/about/HOWDIGITAL_LOGO-c.svg')}`,
						text_01: "怎漾數位",
						text_02: "創意股份有限公司",
					},
					{
						slideimg: `url(${require('@/assets/img/about/com_02.jpg')})`,					
						cityname:'TAIPEI',
						logo: `${require('@/assets/img/about/HOWDESIGN_LOGO-c.svg')}`,
						text_01:"怎漾設計",
						text_02:"創意有限公司",
					},
					{
						slideimg: `url(${require('@/assets/img/about/com_03.jpg')})`,					
						cityname:'SHANGHAI',
						logo:`${require('@/assets/img/about/HOWLINK_LOGO-c.svg')}`,
						text_01: "上海新漾",
						text_02: "信息科技有限公司",
					},
					
				],
				swiperOption: {
					slidesPerView: 3,
					spaceBetween:150,
					loop:true,
					center:true,
					navigation: {
						nextEl: '.swiper-button-prev'
					},
					breakpoints: {
						1023: {
							slidesPerView: 2,
							spaceBetween: 40
						},
						768: {
							slidesPerView: 1,
							spaceBetween: 40
						},
					},
					on:{
						slideChange: ()=>{
							let swiperObj = this.$refs.comSwiper.swiper;
							if(this.windowWidth>1023){
								this.companyfield.comSwiperIndex = swiperObj.activeIndex - 3;
								if(this.companyfield.comSwiperIndex==-1){
									this.companyfield.comSwiperIndex+=3;
								}
							}
							else if(this.windowWidth>768){
								this.companyfield.comSwiperIndex = swiperObj.activeIndex - 2;
								if(this.companyfield.comSwiperIndex==-1){
									this.companyfield.comSwiperIndex+=2;
								}
							}
							else{
								this.companyfield.comSwiperIndex = swiperObj.activeIndex - 1;
								if(this.companyfield.comSwiperIndex==-1){
									this.companyfield.comSwiperIndex+=1;
								}
							}
							if(this.companyfield.comSwiperIndex == this.companyfield.slidedata.length)
								 this.companyfield.comSwiperIndex = 0;
								console.log(this.companyfield.comSwiperIndex);
						}
					}
				}				
			},
			our4pfield:{
				activeIndex:0,
				slidetimer:null,
				our4pdata:[
					{
						title_01:"OUR “4P”",
						title_02:"PHILOSOPHY",
						subtitle:"新4P核心 最懂品牌的心",
						text_01:"Promise 承諾",
						text_02:"追求頂尖，給品牌未來必要的行銷創意",
						aniclass:"ani_01",
						color:"red"
					},
					{
						title_01:"OUR “4P”",
						title_02:"PHILOSOPHY",
						subtitle:"新4P核心 最懂品牌的心",
						text_01:"Professional 專業",
						text_02:"不斷精進技術，創造更多亮眼成果",
						aniclass:"ani_02",
						color:"yellow"
					},
					{
						title_01:"OUR “4P”",
						title_02:"PHILOSOPHY",
						subtitle:"新4P核心 最懂品牌的心",
						text_01:"Possible 可能性",
						text_02:"不先自我預設，敢於挑戰一切",
						aniclass:"ani_03",
						color:"green"
					},
					{
						title_01:"OUR “4P”",
						title_02:"PHILOSOPHY",
						subtitle:"新4P核心 最懂品牌的心",
						text_01:"Perfect 完美",
						text_02:"掌握每一刻，持續好還要更好",
						aniclass:"ani_04",
						color:"blue"
					},
				],
				// video:{
				// 	videoobj: null,
				// 	loop: false,
				// 	endtime: 0,
				// 	init(){
				// 		this.videoobj = document.getElementById('poly_video');
				// 	}
				// }
			},
		}
	},
	methods: {
		//public
		listenScrollDistence: function(){
			this.scrollDistence = window.scrollY;
		},
		ScrollShow: function(target){
			let elOffestTop= target.offsetTop;

			if(this.scrollDistence >= elOffestTop-this.screenHeight/2){
				return true;
			}
			if(this.scrollDistence <= elOffestTop-this.screenHeight){
				return false;
			}
		},
		//specific
		Headlight:function(offset_y){
			let container =this.$refs.lightroad;
			container.innerHTML = "";
			let options = {
				onSpeedUp: (ev) => {					
				},
				onSlowDown: (ev) => {
				},
				
				length: 500,
				roadWidth: 9,
				islandWidth: 1,
				lanesPerRoad: 3,

				fov: 90,
				fovSpeedUp: 150,
				speedUp: 2,
				carLightsFade: 0.4,

				totalSideLightSticks: 50,
				lightPairsPerRoadWay: 50,

				// Percentage of the lane's width
				shoulderLinesWidthPercentage: 0.05,
				brokenLinesWidthPercentage: 0.1,
				brokenLinesLengthPercentage: 0.5,

				/*** These ones have to be arrays of [min,max].  ***/
				lightStickWidth: [0.12, 0.5],
				lightStickHeight: [1.3, 1.7],

				movingAwaySpeed: [60, 80],
				movingCloserSpeed: [-120, -160],

				/****  Anything below can be either a number or an array of [min,max] ****/

				// Length of the lights. Best to be less than total length
				carLightsLength: [400 * 0.05, 400 * 0.15],
				// Radius of the tubes
				carLightsRadius: [0.05, 0.14],
				// Width is percentage of a lane. Numbers from 0 to 1
				carWidthPercentage: [0.3, 0.5],
				// How drunk the driver is.
				// carWidthPercentage's max + carShiftX's max -> Cannot go over 1. 
				// Or cars start going into other lanes 
				carShiftX: [-0.2, 0.2],
				// Self Explanatory
				carFloorSeparation: [0.05, 1],

				colors: {
					roadColor: 0x080808,
					islandColor: 0x0a0a0a,
					background: 0x000000,
					shoulderLines: 0x131318,
					brokenLines: 0x131318,
					/***  Only these colors can be an array ***/
					leftCars: [0xDC5B20, 0xDCA320, 0xDC2020],
					rightCars: [0x334BF7, 0xE5E6ED, 0xBFC6F3],
					sticks: 0xC5E8EB,
				},
				//新增
				rotate_x:0.06,
				rotate_y:0.1+offset_y,
			};

			let lightobj = new infinitelight.App(container, options);
			lightobj.loadAssets().then(lightobj.init);
		},
		NumAdding:function(){
			if(this.numIsShow && !this.numIsAdded){
				let t1 =setInterval(() => {
					if(this.Numberfield.clientsNum<300)
						this.Numberfield.clientsNum+=6;
					else
						clearInterval(t1);
				}, 1);
				let t2 =setInterval(() => {
					if(this.Numberfield.daysNum<3000)
						this.Numberfield.daysNum+=60;
					else
						clearInterval(t2);
				},1);
				
				this.numIsAdded = true;
			}
		},
		//4p
		Our4pbtnClick: function (num) {
			this.our4pfield.activeIndex = num;
			this.SetOur4ptimer();
		},
		SetOur4ptimer:function () {
			clearInterval(this.our4pfield.slidetimer);
			this.our4pfield.slidetimer = setInterval(() => {
				if(this.our4pfield.activeIndex<3)
					this.our4pfield.activeIndex++;
				else
					this.our4pfield.activeIndex = 0;
			}, 6000);
		}
		
	},
	watch: {
		scrollDistence: function(){
			this.numIsShow = this.ScrollShow(this.$refs.Numberfield);
			this.NumAdding();
			this.HeadAniHide=this.ScrollShow(this.$refs.Howamazingfiled);
		},
		windowWidth: function(){
			if(this.windowWidth<=768){
				this.companyfield.slideoffset = 0;
			}
			else{
				this.companyfield.slideoffset = 1;
			}
			// this.Headlight(60/this.windowWidth);
		},
		// HeadAniHide:function () {
		// 	// if(!this.HeadAniHide)
		// 	this.Headlight(60/this.windowWidth);
		// }
		
	},
	created: function(){
		window.addEventListener('scroll', this.listenScrollDistence, true);
		window.addEventListener('resize', () => {
			this.windowWidth = window.innerWidth;
		});
		window.onload=() => {
			let element = document.getElementById("headbanner");
			element.classList.add("load");
		};
	},
	mounted: function(){
		this.windowWidth = window.innerWidth;
		this.SetOur4ptimer();
		// this.Headlight(0);
		setTimeout(() => {
			let element = document.getElementById("headbanner");
			element.classList.add("load");
			// this.Headlight(60/this.windowWidth);
		}, 500);
	},
	
    destroyed: function(){
        window.removeEventListener('scroll', this.listenScrollDistence, true);
    },
	components: {
		swiper,
		swiperSlide
	},
}
</script>

<style scoped lang="scss">
	#aboutContent{		
		overflow-x: hidden;
		//common
		.arrowbtn{
			display: flex;
			img{
				margin-right: 20px;
			}
			.text{
				white-space: nowrap;
			}
			&:hover{
				img{
					animation: arrowHover 1s linear infinite;
				}
			}
			@media screen and (max-width: 480px){
				// flex-direction: column;
				// align-items: flex-start;
				// img{
				// 	order: 2;					
				// }
				// .text{
				// 	order: 1;
				// 	margin-bottom: 15px;
				// }
			}
		}
		//field
		.Headbannerfield{
			width: 100%;
			height: 100vh;
			@extend %background_cover;
			background-image: none;
			position: relative;
			&.load{
				background-image: url('../../../assets/img/about/kv-3.jpg');
				&::after{
					animation:bg-ani 2s forwards;
					animation-delay: 1.5s;
				}
				.starback{
					.star {
						animation:star-ani 1s ease-out;
						animation-direction: normal;
						&::after{
							animation:shooting-ani 1s ease-out;
							animation-direction: normal;
						}
					}
				}
				.textcontent{
					animation:show-after-star 0.1s ease-out forwards;
					animation-delay: 1s;
					.title,
					.text{
						.blink{
							animation: blink-ani 1s ease-out forwards;
							animation-delay: 1.5s;
						}
					}
				}
				.shinebox{
					opacity:1;
				}
			}
			&::after{
				position: absolute;
				top: 0;
				left: 0;
				content: "";
				width: 100%;
				height: 100%;
				@extend %background_cover;
				background-image: url('../../../assets/img/about/kv-3_2.jpg');
				z-index: 0;
			}
			@keyframes bg-ani {
				0% {							
					opacity: 1;
				}
				100% {
					opacity: 0;
				}
			}
			.starback{
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 1;
				.star {
					display:block;			
					position: absolute;;
					transform-origin:100% 0;
					opacity:0;
					top:0%;
					left:45%;
					&::after{
						content:'';
						position:absolute;
						top:0px;
						left:4px;
						border:2px solid #F00;
						border-width:2px 150px 2px 150px; 
						border-color:transparent transparent #0071ce #fff;
						transform:rotate(-45deg) translate3d(1px, -1px, 0);
						transform-origin:0% 100%;
					}
					@keyframes shooting-ani {
						0% {							
						}
						50% {
							transform:scale(1.2) rotate(-45deg) translate(0px, 0px);
						}
						100% {
							transform:scale(0.5) rotate(-45deg) translate(0px, -1px);
						}
					}
					@keyframes star-ani {
						0% {
							opacity:0;
							transform:scale(0) rotate(0) translate3d(0, 0, 0);
						}
						50% {
							opacity:1;
							transform:scale(1) rotate(0) translate3d(-300px, 300px, 0);
						}
						100% {
							opacity:0;
							transform:scale(1) rotate(0) translate3d(-400px, 400px, 0);
						}
					}
					@media screen and (max-width: 1280px){
						left:70%;
						@keyframes star-ani {
							0% {
								opacity:0;
								transform:scale(0) rotate(0) translate3d(0, 0, 0);
							}
							50% {
								opacity:1;
								transform:scale(1) rotate(0) translate3d(-250px, 250px, 0);
							}
							100% {
								opacity:0;
								transform:scale(1) rotate(0) translate3d(-350px, 350px, 0);
							}
						}
					}
					@media screen and (max-width: 768px){
						width:4px;
						height:4px;
						left:80%;
						@keyframes star-ani {
							0% {
								opacity:0;
								transform:scale(0) rotate(0) translate3d(0, 0, 0);
							}
							50% {
								opacity:1;
								transform:scale(1) rotate(0) translate3d(-150px, 150px, 0);
							}
							100% {
								opacity:0;
								transform:scale(1) rotate(0) translate3d(-200px, 200px, 0);
							}
						}
					}
					@media screen and (max-width: 480px){
						width:2px;
						height:2px;
						left:85%;
						@keyframes star-ani {
							0% {
								opacity:0;
								transform:scale(0) rotate(0) translate3d(0, 0, 0);
							}
							50% {
								opacity:1;
								transform:scale(1) rotate(0) translate3d(-100px, 100px, 0);
							}
							100% {
								opacity:0;
								transform:scale(1) rotate(0) translate3d(-150px, 150px, 0);
							}
						}
					}
				}
				.star_02{
					position: absolute;
					top:20%;
					left:45%;
					width: 40px;
					height: 200px;
					@extend %background_100_100;
					background-image: url('../../../assets/img/about/meteor.png');
				}			
				
			}
			.shinebox{
				position: absolute;
				width: 100%;
				height: 100%;
				overflow: hidden;
				z-index: 0;
				opacity: 0;
				#lightroad{
					&.hide{
						display: none;
					}
					position: absolute;
					left: 0px;
					bottom: -30px;
					width: 100%;
					height: 100%;
					canvas {
						width: 100%;
						height: 100%;
					}
				}
				@media screen and (max-width: 1023px){
					display: none;				
				}
				
			}
			.textcontent{
				position: absolute;
				top: 32%;
				left: 0%;
				padding: 0 10%;
				opacity: 0;
				z-index: 1;
				.title,
				.text{
					max-width: 480px;
					color: $color-white;
					position: relative;
					.blink{
						position: absolute;
						top: 0px;
						left: 65%;
						display: inline-block;
						width: 20px;
						left: 312px;
						height: 20px;
						opacity: 0;
						@extend %background_100;
						background-image: url('../../../assets/img/about/blinking_star.png');
						transform-origin: center;
						@media screen and (max-width: 1280px){
							top: 0;
							left: 277px;
						}
						@media screen and (max-width: 768px){
							top: -3px;
							left: 215px;
						}
						@media screen and (max-width: 480px){
							top: -3px;
							left: 195px;
						}
						@keyframes blink-ani {
							0% {
								opacity:0;
								transform: rotate(0);
							}
							50%{
								opacity: 0.8;
								transform: rotate(180deg) scale(1.1);
							}							
							100% {
								opacity:0;
								transform: rotate(360deg);
							}
						}
					}
				}
				.title{
					width: 100%;
					margin-bottom: 30px;
					font-weight: 600;
				}
				@keyframes show-after-star {
					0% {
						opacity:0;
					}
					100% {
						opacity:1;
					}
				}
			}
			@media screen and (max-width: 1023px){			
				&.load{
					background-image: url('../../../assets/img/about/kv-iphone.jpg');
				}
				&::after{
					display: none;
				}
			}
		}
		.Numberfield{
			width: 100%;
			margin: 80px 0;
			.numbercontent{
				padding: 5% 0;
				display: flex;
				align-items: center;
				.item{
					width: 35%;
					padding-left:5%;
					.numbox{
						display: flex;
						align-items: flex-end;
						margin-bottom: 15px;
						.num{
							font-weight: 600;
							margin-right: 5px;
							line-height: 0.8;
							&.active{
								color: aqua;
							}
							sup{
								font-size: 24px;
							}
						}
						.text{
						}
					}
					&:nth-child(1){
						width: 30%;
						padding: 0;
						.text{
							white-space: nowrap;
						}

					}
					&:nth-child(2){
						border-left: 1px solid $color-dark-grey;
						border-right: 1px solid $color-dark-grey;
						.text{
							white-space: nowrap;
						}
					}
					&:nth-child(3){
						.text{
							display: flex;
							flex-direction: column;
							span{
								white-space: nowrap;
							}
						}
					}
				}
			}
			@media screen and (max-width: 1280px){
				.numbercontent{
					.item{
						.numbox{
							.num{
								font-size: 2.2em;
							}
						}
					}
				}		
			}
			@media screen and (max-width: 768px){
				.numbercontent{
					flex-wrap: wrap;
					width: 70%;
					.item{
						width: 100%;
						padding-left:0;
						margin: 30px 0;
						.numbox{
							display: flex;
							.num{
								text-align: left;
								font-weight: 600;
								margin-right: 5px;
							}
						}
						&:nth-child(1){
							width: 50%;
							padding-left:0;
						}
						&:nth-child(2){
							width: 50%;
							border-right: 0;
							padding-left: 10%;
						}
						&:nth-child(3){
							.text{
								flex-direction: row;
							}
						}
					}
				}
			}
			@media screen and (max-width: 480px){
				margin: 60px 0;
				.numbercontent{
					width: 90%;
				}
			}
			@media screen and (max-width: 420px){
				.numbercontent{
					width: 90%;
					.item{
						
						&:nth-child(2){
							padding-left: 5%;
						}						
						.numbox{
							.num{
								font-size: 28px;
							}
						}
						.text{
							font-size: 16px;
						}
					}
				}
			}
		}
		.Howamazingfiled{
			width: 100%;
			margin-bottom: 120px;
			.howamzingcontent{
				display: flex;
				.textfield{
					width: 100%;
					padding-left: 10%;
					position: relative;
					z-index: 0;
					.textbox{
						position: relative;
						padding-top: 120px;
						padding-bottom: 80px;
						margin-bottom: 30px;
						.title{
							font-weight: 600;
							margin-bottom: 5px;
						}
						.subtitle{
							margin-bottom: 30px;
						}
						.text{
							max-width: 600px;
						}
						&::before{
							position: absolute;
							content: "";
							width: 200%;
							height: 100%;
							top: 0;
							left: -30%;
							z-index: -1;
							background-color: $color-white-02;
						}
					}										
				}
				.imgfield{
					width: 500px;
					margin-top: 40px;
					position: relative;
					z-index: 1;
					video{
						width: 100%;
					}
				}
			}
			@media screen and (max-width: 768px){
				.howamzingcontent{
					flex-direction: column;
					align-items: flex-end;
					padding-bottom: 20px;
					.textfield{
						padding-left: 0%;
						order:1;
						.textbox{
							
							.text{
								max-width: 600px;
								width: 50%;
							}
						}										
					}
					.imgfield{
						order:2;
						width: 40%;
						margin-top: -200px;
						//box-shadow: 5px 5px 5px #aaaaaa;
						video{
							width: 100%;
						}
					}
				}
			}
			@media screen and (max-width: 480px){
				margin-bottom: 80px;
				.howamzingcontent{
					.textfield{
						.textbox{
							.text{
								width: 100%;
							}
						}										
					}
					.imgfield{
						width: 50%;
						margin-top: -100px;
					}
					.arrowbtn{
						flex-direction: column;
						align-items: flex-start;
						img{
							order: 2;					
						}
						.text{
							order: 1;
							margin-bottom: 15px;
						}
					}
				}
			}
		}
		.companyfield{
			margin-bottom: 120px;
			.companycontent{
				position: relative;
				.titlefield{
					display: flex;
					justify-content: space-between;
					align-items: flex-end;
					padding-right: 10%;
					margin-bottom: 90px;
					position: relative;
					z-index: 2;
					.textbox{
						width: 300px;
						padding: 40px;
						background-color: $color-white-03;
						margin-bottom: -300px;
						.top{
							margin-bottom: 40px;
							.text{
								color: $color-light-grey-02;
								margin-bottom: 10px;
							}
							.dash{
								width: 30px;
								height: 2px;
								background-color: $color-light-grey-02;
							}
						}
						.center{
							// height: 30px;
							max-width: 190px;
							margin-bottom: 20px;
						}
						.bottom{
							.text{
								margin-bottom: 10px
							}
							.arrowbtn{
								position: relative;
								margin-bottom: 30px;
								.swiper-button-prev{
									margin-left: -10px;
									width: 50px;
									height: 50px;
									background-position: center;
									background-repeat: no-repeat;
									background-size: 100% 100%;
									background-image: url('~@/assets/img/index/arrow.svg');
								}
							}
						}
					}
					.titlebox{
					}
				}
				.swiperbox{
					position: relative;
					z-index: 1;
					width: 180%;
					transform: translateX(-22.5%);
					.swiperitem{
						@extend %background_100;
						padding-bottom: 55%;
						transform: opacity 0.3s ease;
						opacity: 0.3;
					}
					.swiper-slide-next{
						.swiperitem{
							opacity: 1;
						}
					}
				}
				
			}
			@media screen and (max-width: 1023px){
				.companycontent{
					.titlefield{						
						.textbox{						
							.top{
								margin-bottom: 20px;								
							}							
						}
						.titlebox{
						}
					}
					.swiperbox{
						width: 120%;
						transform: translateX(-20%);
					}
				}
			}
			@media screen and (max-width: 768px){
				.companycontent{
					.titlefield{
						padding-right: 5%;					
						.textbox{
							width: 250px;					
							margin-bottom: -300px;							
						}
					}
					.swiperbox{
						width: 80%;
						margin: 0 auto;
						transform: translateX(0%);
						.swiperitem{
							opacity: 1;
							background-size: cover;
							padding-bottom: 80%;
						}
					}
				}
			}
			@media screen and (max-width: 580px){
				margin-bottom: 0px;
				.companycontent{
					.titlefield{						
						.textbox{
							// width: auto;
							padding: 20px;
							margin-bottom: -260px;
							.center{
								margin-bottom: 10px;
							}
							.bottom{
								
							}
						}
					}			
					.swiperbox{
						width: 100%;
					}
					.arrowbtn{
						.swiper-button-prev{
							width: 40px;
							height: 40px;
						}
					}
				}
			}
			@media screen and (max-width: 480px){
				.companycontent{
					.titlefield{						
						.textbox{
							width: 200px;
							margin-bottom: -240px;
							.center{
								// height: 25px;
							}
						}
					}
				}
			}
			@media screen and (max-width: 380px){
				.companycontent{
					.titlefield{						
						.titlebox{
							margin-left: -50px;
						}
					}
				}
			}
		}
		.our4pfield{
			background-color: $color-white-02;
			padding: 180px 0;
			.our4pcontent{
				position: relative;
				.left{
					.titlebox{
						margin-bottom: 40px;
						.title{
							margin-bottom: 15px;
						}					
					}
					.textbox{
						margin-bottom: 80px;
						.text{
							max-width: 300px;
							margin-bottom: 10px;
							&.green{
								color: $color-green;
							}
							&.red{
								color: $color-red;
							}
							&.yellow{
								color: $color-yellow;
							}
							&.blue{
								color: $color-blue;
							}
						}
					}
					.btnbox{
						display: flex;
						.btn{
							width: 10px;
							height: 10px;
							margin-right:20px;
							background-color: $color-black;
							opacity: 0.1;
							transform: rotate(45deg);
							cursor: pointer;
							&.active{
								background-color: $color-green;
								opacity: 1;
								&.green{
									background-color: $color-green;
								}
								&.red{
									background-color: $color-red;
								}
								&.yellow{
									background-color: $color-yellow;
								}
								&.blue{
									background-color: $color-blue;
								}
							}
						}
					}
				}
				.right{
					position: absolute;
					width: 50%;
					height: 100%;
					top: 0;
					left: 50%;
					img{
						position: absolute;
						transform: translate(-50%,-50%);
						z-index: 5;
					}

					@mixin our4p_ani_loop($aniclass,$animation_name_ori,$ori_end,$ori_left,$ori_width,$ori_rotate,$animation_name,$start,$end,$left,$width,$rotate) {
						&.#{$aniclass}{
							animation: #{$animation_name_ori} 1s forwards , #{$animation_name} 3.5s 1s infinite;
							@keyframes #{$animation_name_ori} {
								0% {
									top: $ori_end;
									left: $ori_left;
									width: $ori_width;
									transform:translate(-50%,-50%) rotate($ori_rotate);
								}
								100% {
									top: $start;
									left: $left;
									width: $width;
									transform:translate(-50%,-50%) rotate($rotate);
								}
							}
							@keyframes #{$animation_name} {
								0% {
									top: $start;
									left: $left;
									width: $width;
									transform:translate(-50%,-50%) rotate($rotate);
								}
								50% {
									top: $end;
									left: $left;
									width: $width;
									transform:translate(-50%,-50%) rotate($rotate+random(15)-7.5);
								}
								100% {
									top: $start;
									left: $left;
									width: $width;
									transform:translate(-50%,-50%) rotate($rotate);
								}
							}
						}
					}
					#red_ball_01{
						top: 40%;
						left: 55%;
						width: 25%;
						@include our4p_ani_loop('ani_01','redball01_aniori_01',14%,8%,12%,0deg,'redball01_ani_01',40%,50%,55%,25%,0deg);	
						@include our4p_ani_loop('ani_02','redball01_aniori_02',50%,55%,25%,0deg,'redball01_ani_02',70%,75%,90%,15%,0deg);	
						@include our4p_ani_loop('ani_03','redball01_aniori_03',75%,90%,15%,0deg,'redball01_ani_03',110%,115%,60%,18%,0deg);	
						@include our4p_ani_loop('ani_04','redball01_aniori_04',115%,60%,18%,0deg,'redball01_ani_04',10%,14%,8%,12%,0deg);	
					}
					#red_ball_02{
						top: 45%;
						left: 10%;
						width: 15%;
						@include our4p_ani_loop('ani_01','redball02_aniori_01',108%,68%,5%,0deg,'redball02_ani_01',45%,48%,10%,15%,0deg);
						@include our4p_ani_loop('ani_02','redball02_aniori_02',48%,10%,15%,0deg,'redball02_ani_02',80%,83%,10%,6%,0deg);
						@include our4p_ani_loop('ani_03','redball02_aniori_03',83%,10%,6%,0deg,'redball02_ani_03',0%,3%,70%,5%,0deg);
						@include our4p_ani_loop('ani_04','redball02_aniori_04',3%,70%,5%,0deg,'redball02_ani_04',105%,108%,68%,5%,0deg);
					}
					#blue_ball_01{
						top: 65%;
						left: 35%;
						width: 10%;
						@include our4p_ani_loop('ani_01','blue_ball_01_aniori_01',85%,83%,16%,0deg,'blue_ball_01_ani_01',65%,67%,35%,10%,0deg);			
						@include our4p_ani_loop('ani_02','blue_ball_01_aniori_02',67%,35%,10%,0deg,'blue_ball_01_ani_02',55%,59%,55%,10%,0deg);
						@include our4p_ani_loop('ani_03','blue_ball_01_aniori_03',59%,55%,10%,0deg,'blue_ball_01_ani_03',95%,99%,30%,8%,0deg);
						@include our4p_ani_loop('ani_04','blue_ball_01_aniori_04',99%,30%,8%,0deg,'blue_ball_01_ani_04',80%,85%,83%,16%,0deg);
					}
					#blue_ball_02{
						top: 10%;
						left: 90%;
						width: 8%;
						@include our4p_ani_loop('ani_01','blue_ball_02_aniori_01',5%,28%,13%,0deg,'blue_ball_02_ani_01',10%,13%,90%,8%,0deg);
						@include our4p_ani_loop('ani_02','blue_ball_02_aniori_02',13%,90%,8%,0deg,'blue_ball_02_ani_02',5%,8%,70%,5%,0deg);
						@include our4p_ani_loop('ani_03','blue_ball_02_aniori_03',8%,70%,5%,0deg,'blue_ball_02_ani_03',10%,15%,10%,12%,0deg);
						@include our4p_ani_loop('ani_04','blue_ball_02_aniori_04',15%,10%,12%,0deg,'blue_ball_02_ani_04',0%,5%,28%,13%,0deg);
					}
					#yellow_ball_01{
						top: 30%;
						left: 35%;
						width: 5%;
						@include our4p_ani_loop('ani_01','yellow_ball_01_aniori_01',50%,8%,8%,0deg,'yellow_ball_01_ani_01',30%,33%,35%,5%,0deg);					
						@include our4p_ani_loop('ani_02','yellow_ball_01_aniori_02',33%,35%,5%,0deg,'yellow_ball_01_ani_02',62%,67%,40%,5%,0deg);					
						@include our4p_ani_loop('ani_03','yellow_ball_01_aniori_03',67%,40%,5%,0deg,'yellow_ball_01_ani_03',40%,45%,0%,5%,0deg);					
						@include our4p_ani_loop('ani_04','yellow_ball_01_aniori_04',45%,0%,5%,0deg,'yellow_ball_01_ani_04',45%,50%,8%,8%,0deg);					
					}
					#yellow_ball_02{
						top: 95%;
						left: 47%;
						width: 3%;
						@include our4p_ani_loop('ani_01','yellow_ball_02_aniori_01',45%,92%,3%,0deg,'yellow_ball_02_ani_01',95%,98%,47%,3%,0deg);
						@include our4p_ani_loop('ani_02','yellow_ball_02_aniori_02',98%,47%,3%,0deg,'yellow_ball_02_ani_02',107%,115%,20%,20%,0deg);
						@include our4p_ani_loop('ani_03','yellow_ball_02_aniori_03',115%,20%,20%,0deg,'yellow_ball_02_ani_03',35%,40%,45%,4%,0deg);
						@include our4p_ani_loop('ani_04','yellow_ball_02_aniori_04',40%,45%,4%,0deg,'yellow_ball_02_ani_04',40%,45%,92%,3%,0deg);
					}
					#yellow_ball_03{
						top: 60%;
						left: 75%;
						width: 10%;
						@include our4p_ani_loop('ani_01','yellow_ball_03_aniori_01',80%,38%,3%,0deg,'yellow_ball_03_ani_01',60%,63%,75%,10%,0deg);
						@include our4p_ani_loop('ani_02','yellow_ball_03_aniori_02',63%,75%,10%,0deg,'yellow_ball_03_ani_02',105%,108%,80%,10%,0deg);
						@include our4p_ani_loop('ani_03','yellow_ball_03_aniori_03',108%,80%,10%,0deg,'yellow_ball_03_ani_03',65%,70%,62%,13%,0deg);
						@include our4p_ani_loop('ani_04','yellow_ball_03_aniori_04',70%,62%,13%,0deg,'yellow_ball_03_ani_04',75%,80%,38%,3%,0deg);
					}
					#yellow_ball_04{
						top: 0%;
						left: 75%;
						width: 5%;
						@include our4p_ani_loop('ani_01','yellow_ball_04_aniori_01',5%,53%,5%,0deg,'yellow_ball_04_ani_01',0%,2%,75%,5%,0deg);
						@include our4p_ani_loop('ani_02','yellow_ball_04_aniori_02',2%,75%,5%,0deg,'yellow_ball_04_ani_02',45%,50%,80%,3%,0deg);
						@include our4p_ani_loop('ani_03','yellow_ball_04_aniori_03',50%,80%,3%,0deg,'yellow_ball_04_ani_03',60%,65%,76%,3%,0deg);
						@include our4p_ani_loop('ani_04','yellow_ball_04_aniori_04',65%,76%,3%,0deg,'yellow_ball_04_ani_04',0%,5%,53%,5%,0deg);
					}
					#white_ball{
						top: 75%;
						left: 55%;
						width: 5%;
						@include our4p_ani_loop('ani_01','white_ball_aniori_01',75%,58%,8%,0deg,'white_ball_ani_01',75%,80%,55%,5%,0deg);
						@include our4p_ani_loop('ani_02','white_ball_aniori_02',80%,55%,5%,0deg,'white_ball_ani_02',30%,35%,40%,6%,0deg);
						@include our4p_ani_loop('ani_03','white_ball_aniori_03',35%,40%,6%,0deg,'white_ball_ani_03',35%,40%,80%,10%,0deg);
						@include our4p_ani_loop('ani_04','white_ball_aniori_04',40%,80%,10%,0deg,'white_ball_ani_04',70%,75%,58%,8%,0deg);
					}
					#yellow_halfball{
						top: 0%;
						left: 42%;
						width: 18%;
						@include our4p_ani_loop('ani_01','yellow_halfball_aniori_01',25%,43%,10%,0deg,'yellow_halfball_ani_01',0%,8%,42%,18%,0deg);						
						@include our4p_ani_loop('ani_02','yellow_halfball_aniori_02',8%,42%,18%,0deg,'yellow_halfball_ani_02',25%,35%,60%,25%,0deg);				
						@include our4p_ani_loop('ani_03','yellow_halfball_aniori_03',35%,60%,25%,0deg,'yellow_halfball_ani_03',100%,108%,10%,18%,30deg);						
						@include our4p_ani_loop('ani_04','yellow_halfball_aniori_04',108%,10%,18%,30deg,'yellow_halfball_ani_04',20%,25%,43%,10%,0deg);						
					}
					#white_balltape{
						top: 95%;
						left: 30%;
						width: 20%;
						@include our4p_ani_loop('ani_01','white_balltape_aniori_01',118%,38%,18%,0deg,'white_balltape_ani_01',95%,105%,30%,20%,0deg);						
						@include our4p_ani_loop('ani_02','white_balltape_aniori_02',105%,30%,20%,0deg,'white_balltape_ani_02',90%,95%,40%,15%,0deg);						
						@include our4p_ani_loop('ani_03','white_balltape_aniori_03',95%,40%,15%,0deg,'white_balltape_ani_03',60%,65%,40%,14%,0deg);						
						@include our4p_ani_loop('ani_04','white_balltape_aniori_04',65%,40%,14%,0deg,'white_balltape_ani_04',110%,118%,38%,18%,0deg);						
					}
					#blue_square{
						top: 0%;
						left: 20%;
						width: 13%;
						@include our4p_ani_loop('ani_01','blue_square_aniori_01',70%,53%,28%,0deg,'blue_square_ani_01',0%,5%,20%,13%,0deg);				
						@include our4p_ani_loop('ani_02','blue_square_aniori_02',5%,20%,13%,0deg,'blue_square_ani_02',95%,100%,65%,13%,0deg);				
						@include our4p_ani_loop('ani_03','blue_square_aniori_03',100%,65%,13%,0deg,'blue_square_ani_03',88%,93%,90%,17%,50deg);
						@include our4p_ani_loop('ani_04','blue_square_aniori_04',93%,90%,17%,50deg,'blue_square_ani_04',60%,70%,53%,28%,0deg);
					}
					#white_square{
						top: 40%;
						left: 90%;
						width: 5%;
						@include our4p_ani_loop('ani_01','white_square_aniori_01',65%,10%,5%,0deg,'white_square_ani_01',40%,45%,90%,5%,0deg);						
						@include our4p_ani_loop('ani_02','white_square_aniori_02',45%,90%,5%,0deg,'white_square_ani_02',10%,15%,20%,5%,0deg);						
						@include our4p_ani_loop('ani_03','white_square_aniori_03',15%,20%,5%,0deg,'white_square_ani_03',60%,65%,4%,5%,0deg);						
						@include our4p_ani_loop('ani_04','white_square_aniori_04',65%,4%,5%,0deg,'white_square_ani_04',60%,65%,10%,5%,0deg);						
					}
					#red_square{
						top: 95%;
						left: 65%;
						width: 20%;
						@include our4p_ani_loop('ani_01','red_square_aniori_01',55%,80%,10%,0deg,'red_square_ani_01',95%,105%,65%,20%,0deg);
						@include our4p_ani_loop('ani_02','red_square_aniori_02',105%,65%,20%,0deg,'red_square_ani_02',115%,120%,55%,6%,0deg);
						@include our4p_ani_loop('ani_03','red_square_aniori_03',120%,55%,6%,0deg,'red_square_ani_03',50%,55%,90%,6%,0deg);
						@include our4p_ani_loop('ani_04','red_square_aniori_04',55%,90%,6%,0deg,'red_square_ani_04',50%,55%,80%,10%,0deg);
					}
					#green_tube_01{
						top: 0%;
						left: 65%;
						width: 15%;
						@include our4p_ani_loop('ani_01','green_tube_01_aniori_01',92%,10%,18%,110deg,'green_tube_01_ani_01',0%,5%,65%,15%,110deg);
						@include our4p_ani_loop('ani_02','green_tube_01_aniori_02',5%,65%,15%,110deg,'green_tube_01_ani_02',50%,58%,8%,18%,150deg);
						@include our4p_ani_loop('ani_03','green_tube_01_aniori_03',58%,8%,18%,150deg,'green_tube_01_ani_03',50%,60%,25%,25%,110deg);
						@include our4p_ani_loop('ani_04','green_tube_01_aniori_04',60%,25%,25%,110deg,'green_tube_01_ani_04',85%,92%,10%,18%,110deg);
					}
					#green_tube_02{
						top: 80%;
						left: 90%;
						width: 13%;
						@include our4p_ani_loop('ani_01','green_tube_02_aniori_01',18%,75%,18%,340deg,'green_tube_02_ani_01',80%,85%,90%,13%,340deg);						
						@include our4p_ani_loop('ani_02','green_tube_02_aniori_02',85%,90%,13%,340deg,'green_tube_02_ani_02',30%,35%,85%,8%,340deg);
						@include our4p_ani_loop('ani_03','green_tube_02_aniori_03',35%,85%,8%,340deg,'green_tube_02_ani_03',10%,20%,60%,22%,340deg);
						@include our4p_ani_loop('ani_04','green_tube_02_aniori_04',20%,60%,22%,340deg,'green_tube_02_ani_04',10%,18%,75%,18%,340deg);
					}
					#yellow_tape{
						top: 70%;
						left: 10%;
						width: 22%;
						z-index: 4;
						@include our4p_ani_loop('ani_01','yellow_tape_aniori_01',55%,25%,14%,0deg,'yellow_tape_ani_01',70%,78%,10%,22%,0deg);					
						@include our4p_ani_loop('ani_02','yellow_tape_aniori_02',78%,10%,22%,0deg,'yellow_tape_ani_02',22%,30%,30%,25%,0deg);					
						@include our4p_ani_loop('ani_03','yellow_tape_aniori_03',30%,30%,25%,0deg,'yellow_tape_ani_03',0%,8%,30%,18%,165deg);					
						@include our4p_ani_loop('ani_04','yellow_tape_aniori_04',8%,30%,18%,165deg,'yellow_tape_ani_04',50%,55%,25%,14%,0deg);
					}
				}
				@media screen and (max-width: 1023px){
					.right{
						top: 3%;
						left: 55%;
						width: 40vw;
						height: 25vw;
					}		
				}
				@media screen and (max-width: 768px){
					.left{
						.textbox{
							margin-bottom: 40px;							
						}
					}
				}
				@media screen and (max-width: 620px){					
					.right{
						position: relative;
						width: 80vw;
						height: 45vw;
						left: 52%;
						margin-top: 80px;
						transform: translateX(-50%)
					}
				}
				@media screen and (max-width: 480px){					
					.right{
						
					}	
				}
			
			}
			@media screen and (max-width: 768px){
				padding: 120px 0;
			}
			@media screen and (max-width: 580px){
				padding: 100px 0;
			}
		}
		.lettalkfield{
			padding: 180px 0;
			.lettalkcontent{
				.titlebox{
					margin-bottom: 40px;
					.light{
						position: relative;						
						display: inline-block;
						&::before{
							position: absolute;
							z-index: -1;
							content: "";
							bottom: 0;
							left: 0;
							width: 100%;
							height: 20px;
							background-color: $color-yellow;
						}
					}
				}
				.textbox{
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					align-items: center;
					>.text{
						margin-bottom: 20px;
					}
				}
			}
			@media screen and (max-width: 768px){
				padding: 150px 0;
			}
			@media screen and (max-width: 580px){
				padding: 120px 0;
			}
		}
	}
</style>
